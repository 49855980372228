<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4
									v-if="type == 'already'"
								>{{ $language.auth.check_account }}</h4>
								<h4
									v-else-if="type == 'out'"
								>{{ $language.auth.withdrawn_account }}</h4>
								<h4
									v-else-if="type == 'stop'"
								>{{ $language.auth.stopped_account }}</h4>
								<h4
									v-else-if="type == 'sleep'"
								>{{ $language.auth.dormant_account }}</h4>
								<h4
									v-else-if="type == 'auth'"
								>{{ $language.auth.expired_auth }}</h4>
								<h4
									v-else-if="type == 'not'"
								>{{ $language.auth.no_sign_up_account }}</h4>
								<h4
									v-else
								>{{ $language.common.request_fail }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p
									v-if="type == 'already'"
								>{{ $language.auth.already_sign_up_account }}</p>
								<p
									v-else-if="type == 'out'"
								>{{ msg }}</p>
								<p
									v-else-if="type == 'stop'"
								>{{ msg }}</p>
								<p
									v-else-if="type == 'sleep'"
								>{{ msg }}</p>
								<p
									v-else-if="type == 'auth'"
								>{{ msg }}</p>
								<template
									v-else-if="type == 'third'"
								>
									<p class="error">{{ msg }}</p>
								</template>
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button
								@click="toTry"
								class="btn_l btn_fill_blue"
							>{{ $language.common.ok }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia009'
	,data: function(){
		return {
			program: {
				name: this.$language.auth.check_already_sign_up_account
				, not_header: true
				, not_footer: true
			}
			, type: this.$route.params.type
		}
	}
	, computed: {
		msg: function(){
			let t = this.$language.common.request_to_admin_01

			if(this.$route.path.msg){
				t = this.$route.path.msg
			}

			return t
		}
	}
	,methods: {
		toNext: function(){
			if(this.type == 'already'){
				this.$emit('to', { name: 'login'})
			}else if(this.type == 'out'){
				this.$emit('to', { name: 'login'})
			}else {
				this.$emit('to', { name: 'login'})
			}
		}
		, toTry: function(){
			this.$bus.$emit('logout')
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.$bus.$emit('offLogin')
	}
}
</script>